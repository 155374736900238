.App {
  font-family: sans-serif;
  text-align: center;
  margin: 0 auto;
}

.app-container {
  display: flex;
  height: 100vh;
}

.main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  justify-content: flex-end; /* Align the contents at the bottom of the chat-container */
}

.chat-history {
  display: flex;
  flex-direction: column; /* Reverse the order of chat messages */
  overflow-y: auto;
  margin-bottom: 7vh;
}

.chat-message {
  margin: 1vh;
}

.loading-container {
  display: flex;
  justify-content: center;
  height: 100%; 
  width: 100%;
}

.sidebar {
  width: 25%;
  height: 100vh;
  background-color: #f5f5f5;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}

.transcript-item {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.transcript-item-selected {
  background-color: rgba(0, 0, 0, 0.1);
}


.transcript-item:hover {
  background-color: #eee;
}


form {
  display: flex;
  justify-content: center;
  align-items: center;
}

input {
  width: 60%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 0.5rem;
}

button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

.response {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1rem;
  margin-top: 1rem;
}

.youtube-icon {
  height: 30px; /* Adjust the height as needed */
  width: 30px; /* Adjust the width as needed */
  margin-right: 8px; /* Adjust the margin as needed */
}
